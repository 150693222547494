import { ref, onBeforeMount } from 'vue'

const bannerShown = ref(false)

export default function useBannerShown() {
  const setBannerShown = () => {
    bannerShown.value = true
    sessionStorage.setItem('bannerShown', true)
  }

  onBeforeMount(
    () => (bannerShown.value = sessionStorage.getItem('bannerShown'))
  )

  return {
    bannerShown,
    setBannerShown,
  }
}
