<template>
  <header
    id="header-nav"
    ref="header"
    data-scroll
    data-scroll-sticky
    data-scroll-speed="1"
    data-scroll-target="#root-container"
    class="no-cursor header-nav white"
    :class="`${navOpen ? 'nav-open' : ''} ${
      device === 'tablet' || device === 'mobile' ? 'mobile' : ''
    } ${sticky ? 'sticky-header-hidden ' : ''}${!isHome ? 'dark' : ''}`">
    <div
      v-if="site && site.top_banner.show_banner && !bannerShown"
      class="top-banner">
      <div
        class="banner-content"
        :class="site.top_banner.variation">
        <div class="inner-container">
          <p v-html="site.top_banner.text"></p>
          <span class="divider desktop">|</span>
          <span class="line mobile"></span>
          <a
            :href="site.top_banner.link.url"
            :title="site.top_banner.link.url"
            :target="site.top_banner.link.target"
            v-html="site.top_banner.link.title"></a>
        </div>
        <button
          class="exit"
          @click="setBannerShown()">
          <svg
            class="animation-background"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M5.72 5.72a.75.75 0 011.06 0L12 10.94l5.22-5.22a.75.75 0 111.06 1.06L13.06 12l5.22 5.22a.75.75 0 11-1.06 1.06L12 13.06l-5.22 5.22a.75.75 0 01-1.06-1.06L10.94 12 5.72 6.78a.75.75 0 010-1.06z"
              fill="currentColor" />
          </svg>
        </button>
      </div>
    </div>

    <nav ref="navWrapper">
      <div class="branding">
        <div class="logo-container">
          <a
            href="/"
            @click="close(false)">
            <!-- <DotLottieVue
                  ref="headerAnimation"
                  class="site-logo"
                  :autoplay="false"
                  :src="navAnimation" /> -->
            <img
              v-if="site.site_logo_light?.url"
              class="dark"
              :src="site.site_logo_light?.url || ''"
              alt="site-logo" />
            <img
              v-if="site.site_logo_dark?.url"
              class="light"
              :src="site.site_logo_dark?.url || ''"
              alt="site-logo" />
          </a>
        </div>
        <div
          class="mobile-nav"
          @click="toggle">
          <span class="line line-one" />
          <span class="line line-two" />
          <span class="line line-three" />
        </div>
      </div>
      <div class="nav-container-wrapper">
        <ul
          ref="nav"
          class="nav-container">
          <template v-if="introShown">
            <transition-group
              v-for="(item, index) in menu.menuItems.nodes"
              :key="item.id"
              tag="li"
              appear
              class="nav-container-items"
              name="fade"
              @click="item.cssClasses[0] === '' ? close(false) : null">
              <NavMenuItem
                :key="item.id"
                :data-ind="index"
                :item="item"
                :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                @click="toggle" />
            </transition-group>
          </template>
        </ul>

        <div class="social-container">
          <a
            v-if="site.linkedin"
            :href="site.linkedin"
            target="_blank">
            <svg
              width="39"
              height="38"
              viewBox="0 0 39 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <mask
                id="mask0_882_1266"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="39"
                height="38">
                <rect
                  x="0.190918"
                  width="38"
                  height="38"
                  fill="#BEC2C7" />
              </mask>
              <g mask="url(#mask0_882_1266)"></g>
              <g clip-path="url(#clip0_882_1266)">
                <path
                  d="M38.1909 2.80778V35.1922C38.1909 36.7333 36.9454 38 35.3831 38H2.9987C1.45758 38 0.190918 36.7544 0.190918 35.1922V2.80778C0.190918 1.24556 1.43647 0 2.9987 0H35.3831C36.9243 0 38.1909 1.24556 38.1909 2.80778ZM11.5065 14.2289H5.78536V32.3844H11.5065V14.2289ZM11.9498 8.42333C11.9498 6.52333 10.472 5.15111 8.65647 5.15111C6.84092 5.15111 5.36314 6.54444 5.36314 8.42333C5.36314 10.3022 6.84092 11.6956 8.65647 11.6956C10.472 11.6956 11.9498 10.3022 11.9498 8.42333ZM32.5754 21.1533C32.5754 15.6011 29.1343 13.7222 25.9465 13.7222C22.9909 13.7222 21.0698 15.6222 20.5209 16.7411H20.4576V14.2289H14.9687V32.3844H20.6898V22.5467C20.6898 19.9289 22.3576 18.6411 24.0465 18.6411C25.6509 18.6411 26.8965 19.5489 26.8965 22.4622V32.3844H32.6176V21.1533H32.5754Z"
                  fill="#0845FF" />
              </g>
              <defs>
                <clipPath id="clip0_882_1266">
                  <rect
                    width="38"
                    height="38"
                    fill="white"
                    transform="translate(0.190918)" />
                </clipPath>
              </defs>
            </svg>
          </a>

          <a
            v-if="site.x"
            :href="site.x"
            target="_blank">
            <svg
              width="37"
              height="38"
              viewBox="0 0 37 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.3699 16.5404L11.0427 5.54004H7.13379L16.2136 19.1711L17.3543 20.8832L25.1267 32.5517H29.0357L19.5106 18.2524L18.3699 16.5404Z"
                fill="#0845FF" />
              <path
                d="M33.5378 0H2.67165C1.19372 0 0 1.23286 0 2.74411V35.2559C0 36.7671 1.19372 38 2.67165 38H33.5188C34.9968 38 36.1905 36.7671 36.1905 35.2559V2.74411C36.2094 1.23286 35.0157 0 33.5378 0ZM23.9293 34.4704L16.064 22.458L6.2168 34.4704H3.6721L14.9347 20.732L3.67021 3.52957H12.2612L19.7096 14.9057L29.0339 3.52957H31.5786L20.8389 16.6297L32.5184 34.4684H23.9274L23.9293 34.4704Z"
                fill="#0845FF" />
            </svg>
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
// import { DotLottieVue } from '@lottiefiles/dotlottie-vue'
// import navAnimation from '@/assets/lottie/logo_animated.json?url'
import NavMenuItem from './NavMenuItem.vue'
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/composables/useUtils.js'
import useIntroShown from '@/composables/useIntroShown.js'
import useBannerShown from '@/composables/useBannerShown.js'
import useSmoothScroll from '@/composables/useSmoothScroll.js'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const site = ref(store.acfOptions())
const header = ref()
const sticky = ref()
const navOpen = ref()
const transitioning = ref(false)
const scrollPosition = ref(0)
const { device } = useUtils()
const { introShown } = useIntroShown()
const { bannerShown, setBannerShown } = useBannerShown()
const { smoothScroll } = useSmoothScroll()

// const headerAnimation = ref

const isHome = ref(document.body.classList.contains('home'))

const updateIsHome = () => {
  isHome.value = document.body.classList.contains('home')
}

const observeBodyClass = () => {
  const targetNode = document.body
  const config = { attributes: true, attributeFilter: ['class'] }

  const callback = (mutationsList) => {
    for (let mutation of mutationsList) {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class'
      ) {
        updateIsHome()
      }
    }
  }

  const observer = new MutationObserver(callback)
  observer.observe(targetNode, config)

  return observer
}

let bodyClassObserver

onMounted(() => {
  updateIsHome()
  bodyClassObserver = observeBodyClass()
})

onBeforeUnmount(() => {
  if (bodyClassObserver) {
    bodyClassObserver.disconnect()
  }
})

watch(scrollPosition, (newVal) => {
  scrollPosition.value = newVal
  if (newVal > 20) {
    sticky.value = true
  } else {
    sticky.value = false
  }
})

// watch(introShown, (newVal) => {
//   if (newVal) {
//     const dotLottie = headerAnimation.value.getDotLottieInstance()
//     dotLottie.play()
//     dotLottie.addEventListener('frame', ({ currentFrame }) => {
//       if (currentFrame > 60) {
//         dotLottie.pause()
//       }
//     })
//   }
// })

onMounted(() => {
  // if (introShown.value) {
  //   const dotLottie = headerAnimation.value.getDotLottieInstance()
  //   dotLottie.addEventListener('frame', ({ currentFrame }) => {
  //     if (currentFrame < 60) {
  //       dotLottie.play()
  //     } else {
  //       dotLottie.pause()
  //     }
  //   })
  // }
  // show_hide_header()
  // stickyOnScroll()
  smoothScroll.on('scroll', (e) => {
    handleScroll(e)
  })
})

const handleScroll = (e) => {
  scrollPosition.value = e.animatedScroll // Adjust the threshold as needed
}

const toggle = () => {
  if (device.value !== 'desktop') navOpen.value = !navOpen.value
}
const close = (data) => {
  if (data !== undefined) navOpen.value = data
  else navOpen.value = !navOpen.value
}

// const show_hide_header = () => {
//   if (!header.value) {
//     setTimeout(() => {
//       show_hide_header()
//     }, 100)
//   } else {
//     const site_header = header.value

//     const show_hide_header = gsap
//       .from(site_header, {
//         yPercent: -100,
//         duration: 0.3,
//         ease: 'sine.out',
//       })
//       .progress(1)

//     ScrollTrigger.create({
//       start: 'top top-=' + 100,
//       endTrigger: 'body',
//       end: 'bottom top',
//       markers: false,
//       onUpdate: (self) => {
//         if (self.direction === -1) show_hide_header.play()
//         else show_hide_header.reverse()
//       },
//     })
//   }
// }

// const stickyOnScroll = () => {
//   if (
//     scrollPosition.value > 0 &&
//     device.value !== 'tablet' &&
//     device.value !== 'mobile'
//   ) {
//     header.value.classList.add('sticky')
//     sticky.value = true
//   } else {
//     header.value.classList.remove('sticky')
//     sticky.value = false
//   }
// }

watch(
  () => [navOpen.value, device.value],
  ([newVal, newDevice]) => {
    transitioning.value = true
    if (newVal && newDevice !== 'desktop') {
      smoothScroll.stop()
      gsap.to('.nav-container-items', {
        duration: 1,
        opacity: 1,
        stagger: 0.1,
        delay: 1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    } else if (newDevice === 'desktop') {
      smoothScroll.start()
      gsap.to('.nav-container-items', {
        duration: 1,
        opacity: 1,
        stagger: 0.1,
        delay: 1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    } else if (!newVal && newDevice !== 'desktop') {
      smoothScroll.start()
      gsap.to('.nav-container-items', {
        duration: 0.5,
        opacity: 0,
        stagger: 0.1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    }
  }
)
</script>

<style lang="scss">
.top-banner {
  @apply z-20 p-[6px] relative top-0 left-0 right-0 lg:-mt-6 lg:mb-8 lg:-mx-9;

  .banner-content {
    @apply rounded-[5px] overflow-hidden bg-genesis-jade;

    .inner-container {
      @apply relative flex flex-col items-start lg:flex-row lg:justify-center py-3 pl-6 pr-14 gap-2;

      * {
        @apply text-[14px] m-0;
      }

      a {
        @apply underline;

        &:hover {
          @apply no-underline;
        }
      }

      .line {
        @apply w-full h-[1px] bg-genesis-midnight lg:hidden;
      }

      .divider {
        @apply hidden lg:flex;
      }
    }

    .exit {
      @apply absolute z-5 right-4  top-3 cursor-pointer h-8 w-8 flex justify-center items-center transition-colors rounded-full border border-transparent;

      &:hover {
        @apply border-genesis-blueprint;
      }
    }

    &.blue {
      @apply bg-genesis-blue;

      .inner-container {
        * {
          @apply text-white;
        }

        .line {
          @apply bg-white;
        }
      }

      .exit {
        @apply text-white;

        &:hover {
          @apply border-genesis-white;
        }
      }
    }

    &.dark {
      @apply bg-genesis-blueprint;

      .inner-container {
        * {
          @apply text-white;
        }

        .line {
          @apply bg-white;
        }
      }
      .exit {
        @apply text-white;

        &:hover {
          @apply border-genesis-white;
        }
      }
    }
  }
}

#header-nav.mobile {
  @apply absolute left-0 right-0 top-0;
  nav {
    @apply relative bg-transparent;
  }
  .nav-container-wrapper {
    @apply pointer-events-none absolute left-0 top-full -z-1 h-screen opacity-0 w-screen origin-top bg-genesis-white transition-all delay-700 duration-1000 ease-in-out;
    @apply bg-cover bg-center bg-no-repeat;

    &:before {
      @apply content-[''] h-full w-full absolute opacity-65 bg-genesis-midnight -z-1;
      background: linear-gradient(
        0deg,
        rgba(29, 31, 36, 1) 0%,
        rgba(226, 224, 218, 1) 37%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    .nav-container {
      @apply mx-auto w-[96%] pt-24;
      .nav-container-items {
        @apply bg-transparent opacity-0;
      }
    }
  }

  &.dark {
    .nav-container-wrapper {
      background: rgb(23, 53, 79);
      &:before {
        @apply opacity-20;
        background: rgb(23, 53, 79);
        background: linear-gradient(
          0deg,
          rgba(217, 217, 217, 1) 9%,
          rgba(23, 53, 79, 1) 47%
        );
      }

      &:after {
        @apply content-[''] bg-white absolute top-0 left-0 h-full w-full z-0 pointer-events-none opacity-5;
      }

      .nav-container-items {
        @apply border-genesis-jade;
      }
    }
  }
}

#header-nav {
  @apply absolute left-0 right-0 top-0 shadow-none transition-all duration-300 ease-in-out;

  .logo-container img {
    @apply w-44 transition-all lg:w-full;
  }
  .nav-container-wrapper {
    @apply w-full transition-all lg:h-auto;
  }
}

#header-nav.sticky-header {
  @apply bg-white lg:shadow-md;

  &.dark {
    @apply bg-genesis-blueprint;
  }
}

#header-nav.nav-open {
  @apply max-h-[100vh] fixed lg:absolute lg:max-h-[110px];

  .line-one {
    @apply translate-x-[0px] translate-y-2 rotate-45 transform;
  }
  .line-three {
    @apply -translate-x-[0px] -translate-y-[4px] -rotate-45 transform;
  }
  .line-two {
    @apply opacity-0;
  }

  .nav-container-wrapper {
    @apply pointer-events-auto top-0 opacity-100;
  }
}

.header-nav {
  @apply top-0 z-10 mb-0 mt-0 flex flex-col w-screen max-w-full items-stretch justify-center bg-transparent lg:overflow-visible lg:pb-4 lg:pt-6 lg:px-9;

  nav {
    @apply container flex flex-col items-stretch pt-0 transition-all duration-300 ease-in-out xl:inner-container lg:flex-row lg:items-center lg:justify-between lg:px-0 lg:py-0;

    .nav-container {
      @apply my-0 flex flex-col items-center justify-start px-9 lg:flex-row lg:justify-end lg:gap-6 lg:px-0 xl:gap-10 xl:px-0;
      li {
        @apply w-full border border-t-0 border-l-0 border-r-0 border-b-[1px] border-genesis-blue text-center lg:w-auto lg:border-b-0;
      }
    }
  }
  .branding {
    @apply flex w-full items-center justify-between p-7 text-center shadow-none md:text-left lg:max-h-[60px] lg:w-[242px] lg:flex-[1_0_auto] lg:p-0 xl:p-0;
    .logo-container {
      @apply flex h-full items-center lg:inline-block xl:max-h-full;
      img {
        @apply h-full max-h-[60px] w-[90%] lg:w-auto;

        &.dark {
          @apply hidden;
        }

        &.light {
          @apply flex;
        }
      }
    }
    .mobile-nav {
      @apply float-right flex flex-col p-3 lg:hidden;
      .line {
        @apply mb-1 transition-all duration-[0.5s] ease-in-out;
      }
      .line:before {
        @apply block h-[2px] w-[30px] bg-genesis-blue content-[''];
      }
    }
  }

  .nav-container {
    @apply m-0 flex h-auto w-full items-start justify-start text-left transition duration-500 ease-in-out;
    .nav-container-items {
      @apply flex w-full items-center text-center lg:w-auto;
      div.menu-items {
        @apply text-[24px] xs:text-[40px] flex h-full items-start justify-start text-left py-3 lg:text-[20px];
      }
    }
  }

  .social-container {
    @apply flex w-full flex-row justify-end px-9 mt-14 gap-4 lg:hidden;

    svg {
      path {
        @apply fill-genesis-blue;
      }
    }
  }

  &.dark {
    .nav-container {
      .nav-container-items {
        div.menu-items {
          a {
            @apply text-genesis-white;

            &.router-link-active {
              @apply text-genesis-white lg:text-genesis-jade;

              &:after {
                @apply bg-genesis-jade;
              }
            }
          }
        }
      }
    }

    .logo-container {
      img {
        &.dark {
          @apply flex;
        }

        &.light {
          @apply hidden;
        }
      }
    }

    .social-container {
      svg {
        path {
          @apply fill-genesis-jade;
        }
      }
    }

    .branding {
      .mobile-nav {
        .line {
          &:before {
            @apply bg-genesis-white;
          }
        }
      }
    }
  }
}
</style>
